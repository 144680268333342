import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { HIDDEN, LAYER_ONE, LAYER_TWO } from "constants/z-index";
export const StyledFadeInAssetComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  position: relative;

  .background-asset-container {
    z-index: ${LAYER_ONE};
    position: relative;
    width: 100%;
  }

  .fade-in-asset-container {
    position: absolute;
    top: ${({ startingPosition })=>startingPosition};
    left: 50%;
    transform: translateX(-50%);
    height: ${({ fadeInAssetHeight })=>fadeInAssetHeight};
    z-index: ${HIDDEN};
    opacity: 0;

    img {
      height: ${({ fadeInAssetHeight })=>fadeInAssetHeight};
    }

    &.visible {
      animation: fadeIn ease-in-out forwards;
      z-index: ${LAYER_TWO};
      animation-duration: ${({ animationDuration })=>animationDuration}s;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        top: ${({ startingPosition })=>startingPosition};
      }
      to {
        opacity: 1;
        top: 0;
      }
    }
`;
